<template>
  <div
    :class="[
      `ag-block--itinerary--has-${block.item.image_display}-image`,
      {
        'ag-block--itinerary--has-featured-image': block.item.featured_image,
        'ag-block--itinerary--has-title': block.item.title || block.item.subtitle,
        'ag-block--itinerary--has-subtitle': block.item.subtitle,
        'ag-block--itinerary--has-primary-cta': block.item.primary_cta_label && block.item.primary_cta_link,
        'ag-block--itinerary--has-secondary-cta': block.item.secondary_cta_label && (block.item.secondary_cta_link || block.item.itinerary_image),
        'ag-block--itinerary--has-price': block.item.price,
      },
    ]"
  >
    <div class="ag-block__inner">
      <span v-if="block.item.featured_image" class="ag-block__featured-image">
        <Image v-if="block.item.featured_image" :media="block.item.featured_image" lazy :alt="block.item.title" class="ag-block__featured-image--cover" />
        <Image
          v-if="block.item.image_display === 'full'"
          :media="block.item.featured_image"
          :alt="block.item.title"
          lazy
          class="ag-block__featured-image--full"
        />
      </span>

      <div class="ag-block__body">
        <div class="ag-block__itinerary-details">
          <h1 v-if="block.item.title" class="ag-block__title" v-html="$markdown(block.item.title)" />
          <p v-if="block.item.subtitle" class="ag-block__subtitle">{{ block.item.subtitle }}</p>
          <ul class="ag-block__meta">
            <li v-if="block.item.type?.name" class="ag-block__meta-item">
              <Icon :icon="block.item.type.icon" prefix="far" />
              <span>{{ block.item.type.name }}</span>
            </li>
            <li v-if="block.item.duration && block.item.duration_unit" class="ag-block__meta-item">
              <Icon icon="timer" prefix="far" />
              <span>{{ block.item.duration }} {{ block.item.duration_unit }}</span>
            </li>
            <li v-if="block.item.dates" class="ag-block__meta-item">
              <Icon icon="calendar" prefix="far" />
              <span>{{ block.item.dates }}</span>
            </li>
          </ul>
          <div v-if="block.item.synopsis" v-html="block.item.synopsis" class="ag-block__synopsis" />
        </div>
        <div
          v-if="
            block.item.price ||
            block.item.price_fine_print ||
            (block.item.secondary_cta_label && (block.item.secondary_cta_link || block.item.itinerary_image)) ||
            (block.item.primary_cta_label && block.item.primary_cta_link)
          "
          class="ag-block__itinerary-meta"
        >
          <div v-if="block.item.price" class="ag-block__itinerary-price">
            <span v-if="block.item.price_starting_from" class="ag-block__itinerary-price-from">From</span>
            <strong v-if="block.item.sale_price" class="ag-block__itinerary-price-original">{{ block.item.price }}</strong>
            <strong class="ag-block__itinerary-price-amount">{{ block.item.sale_price || block.item.price }}</strong>
            <span v-if="block.item.price_per_person" class="ag-block__itinerary-price-per-person">per person</span>
          </div>
          <p v-if="block.item.price_fine_print" v-html="block.item.price_fine_print" class="ag-block__itinerary-price-fine-print" />
          <div
            v-if="
              (block.item.secondary_cta_label && (block.item.secondary_cta_link || block.item.itinerary_image)) ||
              (block.item.primary_cta_label && block.item.primary_cta_link)
            "
            class="ag-block__itinerary-buttons"
          >
            <div>
              <Button
                v-if="block.item.secondary_cta_type === 'image' && block.item.secondary_cta_label && block.item.itinerary_image"
                variant="outline"
                full-width
                @click.prevent="onItineraryCtaClick"
                >{{ block.item.secondary_cta_label }}</Button
              >
              <Button
                v-else-if="block.item.secondary_cta_type === 'link' && block.item.secondary_cta_label && block.item.secondary_cta_link"
                as="a"
                variant="outline"
                full-width
                :href="block.item.secondary_cta_link"
                rel="nofollow noindex"
                >{{ block.item.secondary_cta_label }}</Button
              >
            </div>
            <div>
              <Button
                v-if="block.item.primary_cta_label && block.item.primary_cta_link"
                as="a"
                :href="block.item.primary_cta_link"
                target="_blank"
                append-icon="long-arrow-right"
                full-width
                rel="nofollow noindex"
                @click="trackClick({ collection: hashid, content: block.hashid })"
                >{{ block.item.primary_cta_label }}</Button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const Modal = resolveComponent("LazyItineraryModal");

const props = defineProps<{
  block: ItineraryBlock;
}>();

const { hashid } = useRoute().params;
const { trackClick } = useAnalytics();
const { openModal } = useModal();

const onItineraryCtaClick = () => openModal(Modal, { item: props.block.item });
</script>

<style lang="postcss">
.ag-block--itinerary {
  @apply rounded-md border;

  .ag-block {
    &__inner {
      @apply grid h-full md:grid-cols-3;
    }

    &__featured-image {
      @apply relative;

      &--cover {
        @apply relative w-full rounded-t-md md:absolute md:inset-y-0 md:h-full md:rounded-l-md md:rounded-tr-none md:object-cover;
      }

      &--full {
        @apply relative hidden;
      }
    }

    &__body {
      @apply md:col-span-3;
    }

    &__itinerary-details {
      @apply p-8;
    }

    &__subtitle {
      @apply text-supplement;
    }

    &__meta {
      @apply -mt-2 flex flex-wrap;

      &-item {
        @apply mr-6 mt-2 space-x-2.5;

        svg {
          @apply text-primary;
        }
      }
    }

    &__synopsis {
      @apply mt-6;
    }

    &__itinerary-meta {
      @apply border-t p-6;
    }

    &__itinerary-price {
      @apply text-sm;

      &-from {
        @apply mr-1.5;
      }

      &-original {
        @apply mr-1.5 line-through;
      }

      &-amount {
        @apply text-lg text-gray-800;
      }

      &-per-person {
        @apply ml-1.5 text-supplement;
      }

      &-fine-print {
        @apply mt-2 text-xs leading-normal text-supplement;
      }
    }

    &__itinerary-buttons {
      @apply md:shrink-0;
    }
  }

  &--has-featured-image {
    .ag-block__body {
      @apply flex flex-col md:col-span-2 md:justify-between;
    }
  }

  &--has-title {
    .ag-block__meta {
      @apply mt-0;
    }
  }

  &--has-subtitle {
    .ag-block__meta {
      @apply mt-1.5;
    }
  }

  &--has-price {
    .ag-block__itinerary-buttons {
      @apply mt-6;
    }
  }

  &--has-full-image {
    .ag-block__featured-image {
      @apply md:py-8 md:pl-4;

      &::before {
        @apply bg-white/10 md:absolute md:inset-y-0 md:left-0 md:z-10 md:block md:w-24 md:rounded-l-md md:backdrop-blur-sm;
        content: "";
      }

      &--cover {
        @apply md:-ml-4 md:w-24;
      }

      &--full {
        @apply rounded-lg md:z-10 md:block;
      }
    }
  }

  &--has-primary-cta.ag-block--itinerary--has-secondary-cta {
    .ag-block__itinerary-buttons {
      @apply grid gap-4;
    }
  }
}

.ag-columns--3 {
  .ag-col-span-4 .ag-block--itinerary,
  .ag-col-span-6 .ag-block--itinerary {
    .ag-block__itinerary-buttons {
      @apply md:grid md:grid-cols-2 md:gap-4;
    }
  }
}
</style>
